import { AUTH_COOKIE } from '@/middleware';
import { Client as MidasClient, OperationMethods } from '@/types/openapi';
import i18next from 'i18next';
import OpenApiClientAxios from 'openapi-client-axios';
import Cookies from 'universal-cookie';
import definition from '../swagger.json';

export const api = new OpenApiClientAxios({
    definition: definition as unknown as string,
    axiosConfigDefaults: {
        baseURL: process.env.NEXT_PUBLIC_API_BASE,
        withCredentials: true,
    },
});

export async function getClient() {
    const client = await api.getClient<MidasClient>();

    client.interceptors.response.use(
        (response) => response,
        (error) => {
            const cookies = new Cookies(null, { path: '/' });

            if (error.response?.status === 401 && !!cookies.get(AUTH_COOKIE)) {
                cookies.remove(AUTH_COOKIE);
                cookies.remove(AUTH_COOKIE, { path: `/${i18next.language}` });
                window.location.reload();
            }
            return Promise.reject(error);
        },
    );

    return client;
}

export function getOperationUrl<T extends keyof OperationMethods>(
    operation: T,
    params: Parameters<OperationMethods[T]>[0],
) {
    const operationUrl = api.getRequestConfigForOperation(operation, [
        params as any,
        undefined,
        undefined,
    ]).url;

    const downloadUrl = operationUrl.replace(
        'undefined',
        api.axiosConfigDefaults.baseURL!,
    );

    return downloadUrl;
}
